import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'

import '@/assets/less/index.less'

import api from './api/index'

import i18 from './locales/index'

createApp(App).use(i18).use(ElementPlus).use(api,{router}).use(store).use(router).mount('#app')
