import { createRouter, createWebHashHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('../views/home/index.vue')
  },
  {
    path: '/second',
    name: 'second',
    component: () => import('../views/second/index.vue')
  },
  {
    path: '/third',
    name: 'third',
    component: () => import('../views/third/index.vue')
  },
  {
    path: '/fourth',
    name: 'fourth',
    component: () => import('../views/fourth/index.vue')
  },
  {
    path: '/news/:id(\\w+)',
    name: 'news',
    component: () => import('../views/news/index.vue')
  },
  {
    path: '/walletTest',
    name: 'walletTest',
    component: () => import('../views/walletTest/index.vue')
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
