import { ElLoading, ElMessage } from 'element-plus'
import { ethers, Signer, BigNumber, providers } from "ethers";
import {
  connectWeb3Modal,
  disconnectWeb3Modal,
  initWeb3Modal,
} from "./web3Modal";

const web3ModalStore = {
    state: {
        web3Modal: null,

        active: false,
        account: null,
        chainId: 0,

        provider: null,
        selectedAddress: null, // 账号地址

        network: null
    },
    mutations: {
        setWeb3Modal(state, web3Modal) {
            state.web3Modal = web3Modal
        },
        setLibrary(state, library) {
            state.library = library
        },
        setActive(state, active) {
            state.active = active
        },
        setAccount(state, account) {
            state.account = account.toLowerCase()
            localStorage.setItem('account', state.account)
        },
        setChainId(state, chainId) {
            state.chainId = chainId
        },

        setProvider(state, provider) {
            state.provider = provider
        },
        setNetworkName(state, networkName) {
            state.networkName = networkName
        },
        setConnection(state, connection) {
            state.connection = connection;
        },
        setNetwork(state, network) {
            state.network = network;
        }
    },
    actions: {
        async init({state, commit, dispatch}) {
            const modal = await initWeb3Modal();
            if (modal.cachedProvider) {
                try {
                    console.log('modal', modal)
                    // await connect();
                    await dispatch('connect');
                } catch (e) {
                    console.log("Cannot autoconnect:", e);
                    modal.clearCachedProvider();
                }
            } else {
                console.log('modal-else', modal)
                // await connectReadOnly();
                await dispatch('connectReadOnly');
            }
        },
        async connect({state, commit, dispatch}) {
            const connection = await connectWeb3Modal();


            connection.request({
                method: "wallet_switchEthereumChain", 
                params: [{chainId:"0x1"}]
            })
            
            console.log('connection', connection)
            commit('setConnection', connection);

            let provider = new ethers.providers.Web3Provider(connection);
            window.ethers = ethers
            window.provider = provider

            // 获取账户地址
            const accounts = await provider.listAccounts();

            console.log('accounts', accounts)
            if (accounts.length > 0) {
                commit('setAccount', accounts[0])
            }


            provider.getNetwork().then((network) => {
                console.log('network', network)
                commit('setNetworkName', network.name)
            });

            commit('setProvider', provider)
            connection.on("accountsChanged", (accounts) => {
                console.log("User changed account", accounts);
                dispatch('connect')
            });
            connection.on("accounts", (accounts) => {
                console.log("User changed accounts", accounts);
            });
            connection.on("chainChanged", (chainId) => {
                console.log("User changed network", chainId);
                dispatch('connect')
            });
            provider.getNetwork().then(network=>{
                // console.log('network', network)
                commit('setNetwork', network)
            })
        },
        signer({state, commit, dispatch}) {
            const _signer = state.provider.getSigner();
        },
        async disconnect({state, commit, dispatch}) {
            await disconnectWeb3Modal();
            commit('setProvider', null)
            commit('setConnection', {});
            commit('setAccount', '')
            if(localStorage.walletconnect){
                location.reload()
                localStorage.clear()
            }

        }

    }
}

export default web3ModalStore;