import Web3Modal from "web3modal";
import WalletConnectProvider from "@walletconnect/web3-provider";

let web3Modal;
let web3ModalConnection;

/**
 * Initialize Web3Modal.
 *
 * @returns An initialized instance of Web3Modal.
 */
export async function initWeb3Modal() {
  if (web3Modal) {
    return web3Modal;
  }

  const providerOption = new WalletConnectProvider({  
    rpc: {    
      [process.env.VUE_APP_NETWORK_CHAINID]: process.env.VUE_APP_RPC_URL,
    },
    rpcUrl: process.env.VUE_APP_RPC_URL,
    chainId: [process.env.VUE_APP_NETWORK_CHAINID]
  }); 
  web3Modal = new Web3Modal({
    network: 'mainnet',
    cacheProvider: true,
    providerOptions: {
      walletconnect: {
        package: function(){return providerOption},
        options: {
          infuraId: "9aa3d95b3bc440fa88ea12eaa4456161"
        }
      }
    },
  });
  console.log('web3Modal', web3Modal)
  return web3Modal;
}

/**
 * Prompt the user to connect their wallet.
 *
 * @returns A connection to the web3 agent.
 */
export async function connectWeb3Modal() {
  if (!web3Modal) {
    initWeb3Modal();
  }
  if (web3ModalConnection) {
    web3ModalConnection.removeAllListeners();
  }
  web3ModalConnection = await web3Modal.connect()
  return web3ModalConnection;
}

/**
 * Disconnect to the web3 agent.
 */
export async function disconnectWeb3Modal() {
  if (web3Modal) {
    web3Modal.clearCachedProvider();
  }
  if (web3ModalConnection) {
    web3ModalConnection.removeAllListeners();
  }
}
