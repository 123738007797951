<template>
    <div class="wrap" id="main">
        <router-view/>
        <img style="display: none;" :src="'/api/point/index?point=1661925867375.36522408118074545&time='+key" alt="" srcset="" />
    </div>
</template>

<script>
  export default {
    data() {
      return {
        key: ''
      }
    },
    mounted () {
      this.key = +new Date();
    },
  }
  
</script>

<style lang="less">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
</style>
