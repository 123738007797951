import { createI18n } from 'vue-i18n'
import en from './en.json'
import zh from './zh.json'

if (!localStorage.getItem("lang_type")) {
    localStorage.setItem("lang_type", 'English')
}
if (!localStorage.getItem("lang")) {
    localStorage.setItem("lang", 'en')
}
const f = localStorage.getItem("lang")

const i18n = createI18n({
    locale: f,
    messages: {
        en,
        zh
    }
})

export default i18n
