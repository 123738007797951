import axios from 'axios'
import querystring from 'querystring'
import {
    filterData
} from '@/utils'
import url from './url'

let mockUrl = '';

// axios.defaults.withCredentials = true // 跨域带sension
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8'
axios.defaults.transformRequest = function (data) {
    if (data instanceof FormData) {
        return data
    }
    return querystring.stringify(data)
}

axios.interceptors.response.use(response => {
    let data = response.data;
    if (data == "login timeout!"){
        return {
            result: 200,
            message: '登录超时，请重新登录！'
        }
    }else{
        return typeof data === 'object' ? data : JSON.parse(data)
    }
}, error => {
    return Promise.reject(error.response || error) // 返回接口返回的错误信息
})

export default function (Vue, {
    router
}) {
    Vue.prototype = Vue.config.globalProperties;
    Vue.prototype.$toast = Vue.config.globalProperties.$message
    Vue.prototype.$loading = Vue.config.globalProperties.$loading
    // if (!Vue.prototype.$toast) {
    //     console.error('You have to use vant')
    //     return
    // }
    const debug = process.env.NODE_ENV !== 'production'

    let info = (log, ...arg) => {
        debug && console.log(log, ...arg)
    }

    // 统一处理ajax数据返回
    const handleSuccess = (data, success, failure) => {
        if (data.code == '20000') {
            let res = data.data || data.resultParm
            success && success(res)
            info(res)
        } else {
            failure && failure(data)
            console.warn(data)
            _error(data) 
        }
    }

    const _error = err => {
        Vue.prototype.$toast({
            type: 'text',
            message: err && err.message
        })
        console.error(err)
    }

    // 处理ajax最后的异常
    const handleFail = function (err, cb) {
        if (err.status == '404') {
            Vue.prototype.$toast({
                title: '404',
                message: 'uri地址不存在'
            })
        } else if (err.status == '407') {
            Vue.prototype.$toast({
                title: '公告',
                message: '系统维护中',
                showConfirmButton: false,
                closeOnClickModal: false
            })
        } else if (err.status == '413') {
            Vue.prototype.$toast({
                title: '413',
                message: '超出服务器上传限制',
                showConfirmButton: false,
                closeOnClickModal: false
            })
        } else {
            Vue.prototype.$toast({
                title: '提示',
                message: "网络异常" + (err.status ? '：' + err.status : '')
            })
        }
        cb && cb(err)
    }

    const ajax = (config, success, fail, isLoading) => {
        filterData(config.params)
        filterData(config.data)
        let loading = null,
            isLoadingFun = Object.prototype.toString.call(isLoading) === '[object Function]'
        if (fail && isLoading && !isLoadingFun) {
            loading = Vue.prototype.$loading({
                mask: true,
                message: '加载中...'
            })
        }
        isLoadingFun && isLoading(true)
        return new Promise((resolve, reject) => {
            axios(config).then(res => {
                let failure = fail || _error
                loading && loading.close();
                isLoadingFun && isLoading(false)
                handleSuccess(res, success, failure)
                resolve(res)
            }, err => {
                loading && loading.close();
                isLoadingFun && isLoading(false)
                handleFail(err)
                reject(err)
            })
        })
    }


    const handleApi = function () {
        let api = {}
        for (let key in url) {
            if (Object.prototype.hasOwnProperty.call(url, key)) {
                api[key] = ({
                    param,
                    method = 'get',
                    ...res
                }, success, failure = _error, isLoading = true) => {
                    let config = {
                        url: mockUrl + url[key],
                        method: method,
                        ...res
                    }
                    if (method == 'get') {
                        config.params = param
                    } else {
                        config.data = param
                    }
                    return ajax(config, success, failure, isLoading)
                }
            }
        }
        return api
    }

    function getBody(xhr) {
        const text = xhr.responseText || xhr.response;
        if (!text) {
            return text;
        }
        try {
            return JSON.parse(text);
        } catch (e) {
            return text;
        }
    }

    router.beforeEach((to, from, next) => {
        /* 路由发生变化修改页面title */
        if (to.meta.name) {
            document.title = to.meta.name
        }
        next()
    })

    Vue.prototype.$api = window.$api = handleApi()
    Vue.prototype.$ajax = ({
        error = _error,
        success = () => {},
        data = {},
        ...option
    } = {}) => {
        let xhr = new XMLHttpRequest();
        let {
            type,
            url,
            async
        } = option
        let method = type || 'GET'
        xhr.onerror = e => {
            handleFail(e);
        };
        xhr.onload = () => {
            if (xhr.status < 200 || xhr.status >= 300) {
                return handleFail(getBody(xhr), error)
            }
            handleSuccess(getBody(xhr), success, error)
        };

        let params = querystring.stringify(data),
            param = null

        if (method.toLocaleUpperCase() === 'POST') {
            param = params
        } else {
            url = url + "?" + params
        }

        xhr.open(method.toLocaleUpperCase(), url, async ===false ? false : true)

        if (option.withCredentials && 'withCredentials' in xhr) {
            xhr.withCredentials = true;
        }
        const headers = option.headers || {};
        xhr.setRequestHeader("Content-type", "application/x-www-form-urlencoded");
        for (let item in headers) {
            if (Object.prototype.hasOwnProperty.call(headers, item) && headers[item] !== null) {
                xhr.setRequestHeader(item, headers[item]);
            }
        }

        xhr.send(param)

        return xhr
    }
    Vue.prototype.$http = axios
}
