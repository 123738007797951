import { createStore } from 'vuex'
import web3ModalStore from "@/store/modules/wallet";

export default createStore({
  state: {
    off: false, // 关
    goblinSong: null, // 音乐
  },
  mutations: {
    setMusicOf(state,{goblinSong, off}) {
      state.off = off;
      state.goblinSong = goblinSong;
    }
  },
  actions: {
    awaken({state, commit, dispatch}){
      let {goblinSong,off} = state;
      if(goblinSong){
        dispatch('togglePlay')
        return 
      }
      goblinSong=document.createElement("audio")
      goblinSong.src= require("./../assets/music.mp3")
      goblinSong.volume=0.1
      goblinSong.loop=true
      goblinSong.play()
      off = true
      commit('setMusicOf', {goblinSong, off})
    },
    togglePlay({state, commit, dispatch}){
      let {goblinSong,off} = state;
      if(goblinSong.paused){
        goblinSong.play()
        off = true
      }else{
        goblinSong.pause()
        off = !true
      }
      commit('setMusicOf', {goblinSong, off})
    },
  },
  modules: {
    web3Modal: web3ModalStore
  }
})
